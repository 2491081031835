// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-direct-mail-direct-mail-letters-js": () => import("./../src/pages/direct-mail/direct-mail-letters.js" /* webpackChunkName: "component---src-pages-direct-mail-direct-mail-letters-js" */),
  "component---src-pages-direct-mail-direct-mail-postcards-js": () => import("./../src/pages/direct-mail/direct-mail-postcards.js" /* webpackChunkName: "component---src-pages-direct-mail-direct-mail-postcards-js" */),
  "component---src-pages-direct-mail-every-door-direct-mail-js": () => import("./../src/pages/direct-mail/every-door-direct-mail.js" /* webpackChunkName: "component---src-pages-direct-mail-every-door-direct-mail-js" */),
  "component---src-pages-direct-mail-index-js": () => import("./../src/pages/direct-mail/index.js" /* webpackChunkName: "component---src-pages-direct-mail-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-request-a-quote-js": () => import("./../src/pages/request-a-quote.js" /* webpackChunkName: "component---src-pages-request-a-quote-js" */)
}

